.auth { 
    display: flex;
    flex-flow: column;
    gap: 10px;
    max-width: 800px;
    margin: 0 auto;

    &__title { 
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 20px;
    }
}