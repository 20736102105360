@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.app { 
  font-family: 'Open Sans', sans-serif;

  &__body { 
    max-width: 1170px;
    margin: 0 auto;   
    padding-left: 20px;
    padding-right: 20px; 
  }
}