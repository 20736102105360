.button { 
    height: 40px;
    display: inline-flex;
    padding: 0 20px;
    font-weight: 700;
    background: #A4ABAF;
    color: #fff;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    align-self: flex-start;

    &:hover { 
        background: #242d32;
    }

    &[disabled] { 
        background: #A4ABAF !important;
        opacity: 0.5;
    }
}

.unregister { 
    height: 40px;
    display: inline-flex;
    padding: 0 20px;
    font-weight: 700;
    background:black;
    color: #fff;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    align-self: flex-start;

    &:hover { 
        background: #242d32;
    }

    &[disabled] { 
        background: #A4ABAF !important;
        opacity: 0.5;
    }
}