.list { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    &__item { 
        background: #eeeeee;
        display: flex;
        flex-flow: column;
        gap: 10px;
        padding: 20px;

        strong { 
            display: block;
        }
    }

    &__title { 
        font-size: 1.2rem;
        font-weight: 700;
        border-bottom: 1px #E12726 solid;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 750px) {
    .list { 
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 550px) {
    .list { 
        grid-template-columns: 1fr;
    }
}