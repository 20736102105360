.header { 
    background: url('../../images/header-background.jpg') #242D32 top left no-repeat;
    height: 100px;
    margin-bottom: 30px;

    &__inner {
        display: flex;
        max-width: 1170px;
        margin: 0 auto;
        align-items: center;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px
    }

    &__logo { 
        width: 134px;

        img { 
            width: 100%;
        }
    }

    &__logout { 
        margin-left: auto;
        color: #fff;
        cursor: pointer;

        &:hover { 
            text-decoration: underline;
        }
    }

    &__user { 
        margin-left: auto;
        color: #fff;
        text-align: right;
    }
}